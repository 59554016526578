import { createStore } from "vuex";

export default createStore({
  state: {
    accessToken: null,
    userData: null,
    placeholderImg: "/assets/img/placeholder.png",
    isSidebarOpen: true,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
